<template>
  <div class="wrapper live-broadcast-content live-player">
    <div class="live-player-left">
      <div class="video-box">
        <div class="video-box-content">
          <ck-player class="room-Player" :configuration="configuration"></ck-player>
          <a-button type="primary" @click="goBack" >退出</a-button>
        </div>
        <!-- 切换资源 -->
        <div class="video-resources-player" v-show="videoPlayShow">
          <h4>当前直播当前标题</h4>
          <div class="resources-player-content">
            <div class="resources-player-item" v-for="(item, index) in playList" :key="item.id">
              <CloseOutlined class="close-icon" @click="closePlay(index)" />
              <div class="item-player-video">
                <ck-player :configuration="item.configuration"></ck-player>
              </div>
            </div>
            <div class="empty" v-show="playList.length < 2">等待页面进入</div>
          </div>
        </div>
        <div class="video-title">
          <div class="video-title-left">
            <h3>{{ content?.title }}</h3>
            <p>{{ content?.createTime }}</p>
            <p>{{ content?.description }}</p>
            <div style="display: flex">
              <span v-for="(item, i) in content?.videoLabelList" :key="i">
                {{ item?.labelName }}
              </span>
            </div>
          </div>
          <div class="video-title-right">
            <a-avatar class="avatar" :src="content?.userAvatar" :size="70" />
            <strong>{{ content?.nickName }}</strong>
            <span>所属大队：{{ content?.departmentName }}</span>
          </div>
        </div>
      </div>
    </div>
    <LivePlayerInteractive :isShow="true"  />
  </div>
</template>

<script>
import CkPlayer from '@/components/CkPlayer.vue';
import LivePlayerInteractive from '@/components/LivePlayerInteractive.vue';
import { ref, onMounted ,nextTick} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/services';
import { CloseOutlined } from '@ant-design/icons-vue';
import bus from '../../bus';
export default {
  components: {
    LivePlayerInteractive,
    CkPlayer,
    CloseOutlined
  },
  setup() {
    const route = useRoute();
    const configuration = ref({
      id: 'container',
      autoplay: true,
      html5m3u8: true,
      url: route.query.pullFlow
    });

    nextTick( () => {
      document.getElementsByClassName('app-layout_bg-top')[0].setAttribute('style', 'height: 850px');
    })

    // 新增视频浏览记录
    const browseVideo = async () => {
      const { data } = await api.browseVideo({ resourceId: route.query.resourceId });
      console.log(data);
    };

    // 获取视频详情
    const content = ref();
    const getVideoInformation = async () => {
      const { data } = await api.getVideoInformation(route.query.resourceId);
      content.value = data;
    };

    // 资源库 添加直播
    const playList = ref([]);
    const videoPlayShow = ref(false);
    bus.on('addVideoPlay', (params) => {
      publicvideoPlay(params)
    });
    const publicvideoPlay = async item => {
      videoPlayShow.value = true;
      item.configuration = {
        id: `${item.id}`,
        url: '',
        autoplay: false,
        live: false
      };
      if (item.playUrl) {
        item.configuration.url = item.playUrl;
      } else {
        const { data } = await api.watchVideo(item.cameraIndexCode);
        item.configuration.url = data;
      }
      if (playList.value.length >= 2) {
        return false;
      }
      playList.value.push(item);
      console.log(playList);
    };

    // 关闭添加
    const closePlay = index => {
      console.log(index);
      playList.value.splice(index, 1);
      if (playList.value.length <= 0) videoPlayShow.value = false;
    };

    const router = useRouter();
    const goBack = () => {
      nextTick( () => {
        document.getElementsByClassName('app-layout_bg-top')[0].setAttribute('style', 'height: 750px');
      })
      router.go(-1);
    }

    onMounted(() => {
      browseVideo();
      getVideoInformation();
    });

    return {
      configuration,
      // 资源库播放
      playList,
      videoPlayShow,
      closePlay,
      // 视频资源内容
      content,
      goBack
    };
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/less/public.less';
.player-setup-button {
  height: 30px;
  border-radius: 25px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
}
.wrapper {
  position: relative;
  background-color: #f6f6f6;
  width: 1400px;
  margin: 20px auto 260px;
  position: relative;
  z-index: 2;

  &.live-player {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    .live-player-left {
      width: 74.358%;
      border-radius: 10px;
      .video-box {
        position: relative;
        display: flex;
        flex-direction: column;
        .video-box-content {
          position: relative;
          button {
            position: absolute;
            bottom: 200px;
            right: 0;
            width: 90px;
            height: 40px;
            z-index: 99999;
            font-size: 16px;
            color: #0079ed;
            background-color: #fff;
            border-color: #fff;
            padding: 0 10px 0 5px;
            border-radius: 20px 0px 0px 20px;
          }
        }
        .room-Player {
          width: 100%;
          height: 546px;
          border-radius: 10px 0 0 0;
        }
        .video-resources-player {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          width: 520px;
          height: calc(100% - 210px);
          h4 {
            background-color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            color: #fff;
            padding: 6px 0;
            text-align: center;
          }
          .resources-player-content {
            display: flex;
            flex-direction: column;
            height: calc(100% - 30px);
            .resources-player-item,
            .empty {
              flex: 1;
            }
            .empty {
              background: #333333;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #999999;
            }
            .resources-player-item {
              position: relative;
              height: 50%;
              .item-player-video {
                height: 100%;
              }
              .anticon {
                font-size: 11px;
                position: absolute;
                padding: 6px;
                border-radius: 50%;
                color: #fff;
                right: 11px;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.4);
                border: 1px solid rgba(255, 255, 255, 0.7);
                cursor: pointer;
              }
              .close-icon {
                top: 11px;
              }
              // .full-icon {
              //   bottom: 11px;
              // }
              .switch {
                position: absolute;
                font-size: 12px;
                cursor: pointer;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 11px;
                bottom: 58px;
                width: 90px;
                height: 27px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 14px;
                border: 1px solid rgba(255, 255, 255, 0.7);
              }
            }
          }
        }
        .video-title {
          background-color: #fff;
          padding: 20px 0 30px 25px;
          display: flex;
          border-radius: 0 0 10px 10px;
          .video-title-left {
            width: calc(100% - 224px);
            h3 {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
            }
            p {
              color: #666666;
              font-size: 12px;
            }
            p:last-of-type {
              margin: 12px 0 15px;
            }
            span {
              width: 78px;
              height: 24px;
              font-size: 12px;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              border: 1px solid #d4d4d4;
            }
          }
          .video-title-right {
            width: 224px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-left: 1px solid #e5e5e5;
            strong {
              color: #333333;
              margin: 5px 0;
            }
            span {
              font-size: 12px;
              color: #666666;
            }
          }
        }
      }
    }
  }
}
// .app-layout_bg-top {
//   height: 850px
// }
</style>
